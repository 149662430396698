import React from 'react';
import { motion } from 'framer-motion';

const menuItems = [
  { id: 'intro', label: 'Home', icon: '❖' },
  { id: 'about', label: 'About', icon: 'ℹ' },
  { id: 'skills', label: 'Skills', icon: '✦' },
  { id: 'projects', label: 'Projects', icon: '✎' },
  { id: 'contact', label: 'Contact', icon: '✉' }
];

export default function NavigationMenu() {
  const [activeSection, setActiveSection] = React.useState('intro');
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    let observer;
    let mutationObserver;
    
    // Function to create and setup the observer
    const setupObserver = () => {
      if (observer) {
        observer.disconnect();
      }

      observer = new IntersectionObserver(
        (entries) => {
          // Find the most visible section
          let maxVisibility = 0;
          let mostVisibleSection = null;

          entries.forEach((entry) => {
            if (entry.intersectionRatio > maxVisibility) {
              maxVisibility = entry.intersectionRatio;
              mostVisibleSection = entry.target.id.replace('-section', '');
            }
          });

          if (mostVisibleSection && maxVisibility > 0.2) {
            setActiveSection(mostVisibleSection);
          }
        },
        { 
          threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
          rootMargin: '-10% 0px -40% 0px'
        }
      );

      // Observe all sections
      const sections = document.querySelectorAll('section[id]');
      sections.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });
    };

    // Setup mutation observer to watch for new sections
    mutationObserver = new MutationObserver((mutations) => {
      let shouldResetup = false;
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0) {
          shouldResetup = true;
        }
      });
      if (shouldResetup) {
        setupObserver();
      }
    });

    // Start observing the document body for DOM changes
    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true
    });

    // Initial setup
    setupObserver();

    // Cleanup function
    return () => {
      if (observer) {
        observer.disconnect();
      }
      if (mutationObserver) {
        mutationObserver.disconnect();
      }
    };
  }, []); // Empty dependency array since we want this to run only on mount/unmount

  const scrollToSection = (id) => {
    const element = document.getElementById(`${id}-section`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Desktop Navigation - Top Bar */}
      <motion.nav
        className="fixed top-0 left-0 right-0 z-50 hidden md:block"
        initial={{ opacity: 0, y: -100 }}
        animate={{ 
          opacity: 1, 
          y: 0,
          backgroundColor: isScrolled ? 'rgba(3, 7, 18, 0.8)' : 'transparent',
          backdropFilter: isScrolled ? 'blur(12px)' : 'none'
        }}
        transition={{ duration: 0.5 }}
      >
        <div className="max-w-7xl mx-auto px-4 py-2">
          <ul className="flex justify-center items-center gap-2">
            {menuItems.map(({ id, label, icon }) => (
              <motion.li
                key={id}
                className="relative group"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <button
                  onClick={() => scrollToSection(id)}
                  className={`px-3 py-2 rounded-lg flex items-center gap-2 transition-all duration-300
                    ${activeSection === id 
                      ? 'text-[#818cf8] font-semibold' 
                      : 'hover:bg-[#818cf820] text-[#94a3b8]'
                    }`}
                  aria-label={label}
                >
                  <span className={`text-sm ${activeSection === id ? 'text-[#818cf8]' : ''}`}>{icon}</span>
                  <span className={`text-sm font-medium ${activeSection === id ? 'text-[#818cf8]' : ''}`}>{label}</span>
                </button>
              </motion.li>
            ))}
          </ul>
        </div>
      </motion.nav>
    </>
  );
}
